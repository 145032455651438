/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import logo from '../images/logo.png';

function Navbar() {
  const userDetails = useSelector((state) => state.login.userDetails);

  const [imageUrl, setImageUrl] = useState('');
  const [userName, setUserName] = useState('');

  useEffect(() => {
    // retrieve user image and name from local storage
    const savedUserImageUrl = localStorage.getItem('userImageUrl');
    const savedUserName = localStorage.getItem('userName');

    // set user image and name to state
    setImageUrl(savedUserImageUrl);
    setUserName(savedUserName);
  }, [userDetails]);

  return (
    <div className="nav-container">
      <nav className="navbar pl-3 pr-2 is-transparent">
        <div className="navbar-brand">
          <img src={logo} alt="logo" className="pl-2 pt-2 pb-2 image is-300x100 mr-2" />
        </div>

        <div id="navbarExampleTransparentExample" className="navbar-menu">
          <div className="navbar-end">
            <div className="navbar-item">
              <input className="input larger-width" type="text" placeholder="Search for anything..." />
            </div>
          </div>

          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">
                <img src={imageUrl} alt="user-logo" className="mr-2" />
                {userName}
              </div>
              <div className="navbar-dropdown is-boxed">
                <a className="navbar-item"> Settings </a>
                <a className="navbar-item"> Logout </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
