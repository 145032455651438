import axios from 'axios';
import { API_ROUTES } from '.';

export const getProjects = async (token) => {
  const response = await axios({
    url: API_ROUTES.projects['projects:get'],
    method: 'GET',
    headers: { Authorization: `Bearer ${token}`, 'Content-Language': 'en_US' },
    params: {
      page: 1,
      limit: 6,
    }
  });

  return response;
};

export const getTasks = async (token) => {
  const response = await axios({
    url: API_ROUTES.tasks['tasks:get'],
    method: 'GET',
    headers: { Authorization: `Bearer ${token}`, 'Content-Language': 'en_US' },
    params: {
      page: 1,
      limit: 20,
    }
  });

  return response;
};

export default {};
