import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FolderOpen, Image, Settings } from '@material-ui/icons';
import { Link, Outlet } from 'react-router-dom';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
  nav: {
    marginTop: '2vh',
    backgroundColor: theme.palette.background.paper,
    paddingTop: '5vh',
    height: '100vh',
  },
  sideNav: {
    height: '100%',
    width: '100%',
    paddingTop: '0.5vh',
  },
  sideNavItem: {
    marginBottom: '0.5em',
  }
}));

export default function MainComponent() {
  const classes = useStyles();
  // const navigate = useNavigate();

  // retrieve selectedIndex in side nav  from local storage
  const newSelectedIndex = localStorage.getItem('selectedIndex');

  const handleListItemClick = (event, newValue) => {
    // save selectedIndex in side nav to local storage
    localStorage.setItem('selectedIndex', newValue);
  };

  return (
    <Grid container spacing={12}>
      <Grid item xs={12}><Navbar /></Grid>

      {/* TODO: modify side nav in the mobile vision */}
      <Grid item xs={12} lg={2} md={2} className={classes.sideNav}>
        <Card>
          <List className={classes.nav}>
            <ListItem
              className={classes.sideNavItem}
              button
              selected={newSelectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemIcon>
                <FolderOpen />
              </ListItemIcon>
              <Link to="/projects">
                <ListItemText primary="Projects" />
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.sideNavItem}
              selected={newSelectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemIcon>
                <Image />
              </ListItemIcon>
              <Link to="/tasks">
                <ListItemText primary="Tasks" />
              </Link>
            </ListItem>
            <ListItem
              button
              selected={newSelectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </List>
        </Card>
      </Grid>

      <Grid item xs={12} lg={10} md={10}>
        <Outlet />
      </Grid>
    </Grid>
  );
}
