import { GET_PROJECTS, GET_TASKS } from '../actions/projectsActions';

const initialState = {
  projects: [],
  tasks: [],
};

const ProjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload
      };
    case GET_TASKS:
      return {
        ...state,
        tasks: action.payload
      };
    default:
      return state;
  }
};

export default ProjectsReducer;
