import axios from 'axios';
import { API_ROUTES } from '.';

export const getAccountDetails = async (loginForm) => {
  const basicAuth = `Basic ${btoa(`${loginForm.username}:${loginForm.password}`)}`;

  const response = await axios({
    url: API_ROUTES.login['token:get'],
    method: 'POST',
    headers: {
      authorization: basicAuth,
    },
  });

  return response;
};

export const refreshTokenAccess = async (refreshToken) => {
  const response = await axios({
    url: API_ROUTES.login['refreshToken:get'],
    method: 'POST',
    data: {
      refresh_token: refreshToken,
    },
  });

  return response;
};

export default {};
