import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import { FolderOpen, PersonRounded } from '@material-ui/icons';

import { getProjects } from '../config/projectsApi';
import { saveProjects } from '../actions/projectsActions';
// TODO: modify refreshToken functionality
// import { refreshTokenAccess } from '../config/accountApi';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    marginRight: '0.3vw',
  },
  item: {
    width: '482px',
    height: '100%',
    padding: '8vh',
  },
  title: {
    marginTop: '1vw',
    marginLeft: '0.5vw',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
  },
  box: {
    padding: '2vh',
    backgroundColor: 'white',
  },
  projectName: {
    fontFamily: 'Inter',
    fontSize: '25px',
    fontWeight: 'bold',
    lineHeight: '30.26px',
    color: '#000000',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  status: {
    padding: '2px',
    borderRadius: '0.5vw',
    fontFamily: 'Inter',
    fontSize: '20px',
    textAlign: 'center',
    backgroundColor: '#83C29D33',
    color: '#21BD1E',
  },
  projectContent: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '400',
  },
  marginLeft: {
    marginLeft: '0.3vw',
    paddingRight: '4vw',
  },
  newStatus: {
    backgroundColor: '#d9eaf7', // Light Blue
    color: '#007BFF', // Blue text
  },
  completedStatus: {
    backgroundColor: '#d6f5d6', // Light Green
    color: '#21BD1E', // Green text
  },
  inProgressStatus: {
    backgroundColor: '#fff4d6', // Light Orange
    color: '#FF9500', // Orange text
  },
  pendingStatus: {
    backgroundColor: '#f5d6d6', // Light Red
    color: '#FF0000', // Red text
  },
  new: {
    color: '#007BFF', // Blue text
  },
  completed: {
    color: '#21BD1E', // Green text
  },
  inProgress: {
    color: '#FF9500', // Orange text
  },
  pending: {
    color: '#FF0000', // Red text
  },
  divider: {
    border: '0.5px solid black',
    width: '100%',
  },
  listItems: {
    fontSize: '0.8rem',
    marginLeft: '0.3vw',
  },
  personRounded: {
    paddingTop: '5px',
  }
}));

function Projects() {
  const classes = useStyles();
  const dispatch = useDispatch();

  // add useRef to avoid 2 calls for the api
  const hasFetched = useRef(false);
  const token = useSelector((state) => state.login.token);
  const stateProjects = useSelector((state) => state.projects.projects);

  // retrieve token from local storage
  const saveToken = localStorage.getItem('token');
  // const saveRefreshToken = localStorage.getItem('refreshToken');

  // const checkTokenExpiry = async () => {
  //   const expiryTime = localStorage.getItem('tokenExpiryTime');
  //   const remainingTime = expiryTime - Date.now();
  //   console.log('aaaaa', remainingTime, expiryTime);

  //   if (remainingTime <= 0) {
  //     // Token is expired, handle refresh
  //     console.log('Token is expired, handle refresh');
  //     const newRefreshTokenResponse = await refreshTokenAccess(saveRefreshToken);
  //     const newAccessToken = newRefreshTokenResponse.data.data[0].access_token;
  //     const newAccessTokenExpiryTime = newRefreshTokenResponse.data.data[0].token_expiry_time;
  //     console.log('aaa', newRefreshTokenResponse);
  //     // save token to local storage
  //     localStorage.setItem('token', newAccessToken);
  //     localStorage.setItem('tokenExpiryTime', newAccessTokenExpiryTime);
  //   } else {
  //     // Set a timeout to refresh token a few minutes before expiration
  //     const refreshBuffer = 5 * 60 * 1000; // 5 minutes
  //     console.log('aaaaa', refreshBuffer);
  //     // setTimeout(() => {

  //     // }, remainingTime - refreshBuffer);
  //   }
  // };

  const projectsFetch = async () => {
    try {
      const comingProjects = await getProjects(!token ? saveToken : token);

      // save projects to state
      dispatch(saveProjects([...comingProjects.data.data]));
    } catch (error) {
      console.log('projects error');
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      projectsFetch();
    }
    // checkTokenExpiry();
  }, []);

  return (
    <Grid className={classes.marginLeft} container spacing={4}>
      <Grid item xs={12} lg={12} md={12}>
        <Typography className={classes.title}>
          All Projects
        </Typography>
      </Grid>

      {stateProjects && stateProjects.map((project) => (
        <Grid item className={classes.item} xs={12} sm={12} lg={4} xl={4} md={12}>
          <Box className={classes.box}>
            <Grid container xs={12}>
              <Grid item xs={8}>
                <Typography variant="h5" className={classes.projectName} component="h2">
                  {project.name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={`${classes.status} ${project.state === 'New' ? classes.newStatus : project.state === 'Completed' ? classes.completedStatus : project.state === 'In Progress' ? classes.inProgressStatus : classes.pendingStatus}`}>
                  {project.state}
                </Typography>
              </Grid>
              <hr className={classes.divider} />
              <Grid item xs={8}>
                <List>
                  <ListItem>
                    <ListItemText primary={(
                      <Typography variant="body2" className={classes.projectContent}>
                        Start Date:
                        {' '}
                        {project.start_date}
                      </Typography>
)}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={(
                      <Typography variant="body2" color="error" className={classes.projectContent}>
                        Deadline:
                        {' '}
                        {project.start_end}
                      </Typography>
                    )}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={(
                      <Typography variant="body2" className={classes.projectContent}>
                        Manager:
                        <PersonRounded className={classes.personRounded} />
                        {project.manager_id}
                      </Typography>
)}
                    />
                  </ListItem>

                </List>
              </Grid>

              <Grid item xs={4}>
                <List>
                  <ListItem>
                    <ListItemText className={classes.listItems} primary={`${project.tasks.all} Tasks`} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FolderOpen className={classes.new} />
                      <ListItemText className={classes.listItems} secondary={`${project.tasks.pending} Pending`} />
                    </ListItemIcon>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FolderOpen className={classes.inProgress} />
                      <ListItemText className={classes.listItems} secondary={`${project.tasks.in_progress} In Progress`} />
                    </ListItemIcon>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FolderOpen className={classes.completed} />
                      <ListItemText className={classes.listItems} secondary={`${project.tasks.done} Done`} />
                    </ListItemIcon>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default Projects;
