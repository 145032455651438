import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box
} from '@material-ui/core';
import { Attachment, Comment } from '@material-ui/icons';

import { getTasks } from '../config/projectsApi';
import { saveTasks } from '../actions/projectsActions';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    marginRight: '0.3vw',
  },
  item: {
    width: '482px',
    height: '100%',
  },
  title: {
    marginTop: '1vw',
    marginLeft: '0.5vw',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
  },
  box: {
    padding: '1.5vh',
    backgroundColor: 'white',
    borderRadius: '0.5em',
    marginLeft: '1em',
  },
  taskName: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '30.26px',
    color: '#000000',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  taskHeader: {
    backgroundColor: '#d6f5d6', // Light Green
    borderRadius: '15px',
  },
  status: {
    padding: '8px 16px',
    borderRadius: '5px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'inline-block',
  },
  new: {
    backgroundColor: '#d9eaf7', // Light Blue
    color: '#007BFF', // Blue text
  },
  completed: {
    backgroundColor: '#d6f5d6', // Light Green
    color: '#21BD1E', // Green text
  },
  inProgress: {
    backgroundColor: '#fff4d6', // Light Orange
    color: '#FF9500', // Orange text
  },
  pending: {
    backgroundColor: '#f5d6d6', // Light Red
    color: '#FF0000', // Red text
  },
  headerContent: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '16px',
  },
  taskContent: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '16px',
  },
  marginLeft: {
    marginLeft: '0.3vw',
    paddingRight: '4vw',
  },
  attachment: {
    paddingTop: '5px',
    marginBottom: '-6px',
  },
}));

function Tasks() {
  const classes = useStyles();
  const dispatch = useDispatch();

  // useRef to prevent multiple API calls
  const hasFetched = useRef(false);
  const token = useSelector((state) => state.login.token);
  const stateTasks = useSelector((state) => state.projects.tasks);

  // Retrieve token from local storage
  const saveToken = localStorage.getItem('token');

  const tasksFetch = async () => {
    try {
      const comingTasks = await getTasks(token || saveToken);

      // Save tasks to state
      dispatch(saveTasks([...comingTasks.data.data]));
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      tasksFetch();
    }
  }, []);

  return (
    <Grid className={classes.marginLeft} container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title}>Tasks</Typography>
      </Grid>
      <TableContainer component={Box} className={classes.box}>
        <Table>
          <TableHead>
            <TableRow className={classes.taskHeader}>
              <TableCell colSpan={2} className={classes.taskName}>
                Task Details
              </TableCell>
              <TableCell className={classes.headerContent}>Start-Time</TableCell>
              <TableCell className={classes.headerContent}>Deadline</TableCell>
              <TableCell className={classes.headerContent}>State</TableCell>
              <TableCell className={classes.headerContent}>Attachments</TableCell>
              <TableCell className={classes.headerContent}>Comments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stateTasks.map((task) => (
              <TableRow key={task.id || task.name}>
                <TableCell colSpan={2} className={classes.taskName}>
                  <Typography variant="h6" component="h1">
                    {task.name}
                    . (
                    {task.project_name}
                    )
                  </Typography>
                  <Typography variant="body1" className={classes.taskContent} component="h2">
                    {task.description}
                  </Typography>
                </TableCell>
                <TableCell className={classes.taskContent}>{task.start_time}</TableCell>
                <TableCell>
                  <Typography variant="body2" color="error" className={classes.taskContent}>
                    {task.deadline}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={`${classes.status} ${task.state === 'New' ? classes.new : task.state === 'Completed' ? classes.completed : task.state === 'In Progress' ? classes.inProgress : classes.pending}`}>
                    {task.state}
                  </Typography>
                </TableCell>
                <TableCell className={classes.taskContent}>
                  <Typography variant="body2" className={classes.taskContent}>
                    {task.attachments}
                    <Attachment className={classes.attachment} />
                  </Typography>
                </TableCell>
                <TableCell className={classes.taskContent}>
                  <Typography variant="body2" className={classes.taskContent}>
                    {task.notes}
                    <Comment className={classes.attachment} />
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default Tasks;
