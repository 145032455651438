import { combineReducers } from 'redux';

import loginReducer from './loginReducer';
import ProjectsReducer from './projectsReducer';

const rootReducer = combineReducers({
  login: loginReducer,
  projects: ProjectsReducer,
});

export default rootReducer;
