export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_TASKS = 'GET_TASKS';

export const saveProjects = (projects) => ({
  type: GET_PROJECTS,
  payload: projects,
});

export const saveTasks = (tasks) => ({
  type: GET_TASKS,
  payload: tasks,
});
