import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SAVE_REFRESH_TOKEN,
  SAVE_REFRESH_TOKEN_EXPIRY_TIME,
  SAVE_TOKEN_EXPIRY_TIME,
  SAVE_USER_DETAILS
} from '../actions/accountActions';

const initialState = {
  token: '',
  tokenExpiryTime: 0,
  refreshToken: '',
  refreshTokenExpiryTime: 0,
  user: { username: '', password: '' },
  userDetails: {
    mobile: '',
    email: '',
    name: '',
    language: '',
    image: '',
    timezone: ''
  },
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        user: action.payload
      };
    case SAVE_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
      };
    case SAVE_TOKEN_EXPIRY_TIME:
      return {
        ...state,
        tokenExpiryTime: action.payload
      };
    case SAVE_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload
      };
    case SAVE_REFRESH_TOKEN_EXPIRY_TIME:
      return {
        ...state,
        refreshTokenExpiryTime: action.payload
      };
    default:
      return state;
  }
};

export default loginReducer;
