// TODO: make it static url for now
// const { BASE_URL } = process.env;
export const BASE_URL = 'https://dmc-ksa-dev-16251340.dev.odoo.com';

export const API_ROUTES = {
  login: {
    'token:get': `${BASE_URL}/v1/auth/token`,
    'refreshToken:get': `${BASE_URL}/v1/auth/refresh/token`,
  },
  projects: {
    'projects:get': `${BASE_URL}/v1/me/projects`,
  },
  tasks: {
    'tasks:get': `${BASE_URL}/v1/me/tasks`,
  }
};
