import React from 'react';
import {
  Routes, Route, Navigate,
  useLocation
} from 'react-router-dom';

import Login from './features/Login';
import Projects from './features/Projects';
import MainComponent from './components/MainComponent';
import Tasks from './features/Tasks';

import './style/App.css';
import 'bulma/css/bulma.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  const location = useLocation();

  const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return token !== null;
  };

  return (
    <div className={(location.pathname === '/auth' || location.pathname === '/') ? '' : 'App'}>
      <Routes>
        <Route path="/" element={isAuthenticated() ? <Navigate to="/projects" /> : <Navigate to="/auth" />} />
        <Route element={<MainComponent />}>
          <Route path="/projects" element={<Projects />} />
          <Route path="/tasks" element={<Tasks />} />
        </Route>
        <Route path="/auth" element={isAuthenticated() ? <Navigate to="/projects" /> : <Login />} />
      </Routes>
    </div>
  );
}

export default App;
